.input {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.lable {
  color: var(--Gray-700, #344054);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.login-form {
  display: flex;
  max-width: 360px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
}
