.box-shadow {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

/* In your CSS file, e.g., styles.css */
.input-focus:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 4px #e8dffd; /* Increase box shadow width and apply custom color */
  border-color: #d6bbfb; /* Apply custom border color */
  border-width: 1.5px; /* Increase border width */
}

.download-form-text {
  color: var(--Coverzen-Blue, #0439d7);
}

.brand-text {
  color: var(--Gray-700, #344054);
}
.sub-brand-text {
  color: var(--Gray-600, #475467);
}
