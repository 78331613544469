@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-checkbox:checked {
  background-color: #6b46c1 !important; /* Tailwind's purple-600 color */
  border-color: #6b46c1 !important;
}

@font-face {
  font-family: satoshi-medium;
  src: url(../AllFonts/Font/Satoshi-Medium.ttf);
}

.satoshi-medium {
  font-family: satoshi-medium;
}

body {
  font-family: satoshi-medium;
}

@font-face {
  font-family: satoshi-bold;
  src: url(../AllFonts/FontBold/Satoshi-Bold.ttf);
}
.satoshi-bold {
  font-family: satoshi-bold;
}
@font-face {
  font-family: satoshi-light;
  src: url(../AllFonts/FontLight/Satoshi-Light.ttf);
}
.satoshi-light {
  font-family: satoshi-light;
}

.input {
  border-radius: 4px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  /* Shadows/shadow-xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.input-focus:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 4px #e8dffd; /* Increase box shadow width and apply custom color */
  border-color: #d6bbfb; /* Apply custom border color */
  border-width: 1.5px; /* Increase border width */
}

.box-shadow {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.custom-btn-focus:focus{
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 4px #e8dffd; /* Increase box shadow width and apply custom color */
  border-color: #d6bbfb; /* Apply custom border color */
  border-width: 1.5px; /* Increase border width */ 
}
